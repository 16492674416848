<template>
  <div
    class="px-2 option-container d-flex align-center"
    :class="{'w-full':$vuetify.breakpoint.xs}"
  >
    <v-menu
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          color="primary"
          v-bind="attrs"
          class="px-2 camel-case option-btn"

          v-on="on"
        >
          <v-icon left>
            mdi-file-download-outline
          </v-icon>
          {{ $t('labels.export.title') }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadLeads">
          <v-list-item-title>{{ $t('labels.export.all-contacts') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="downloadLeads({onlyNotExported :true})">
          <v-list-item-title>{{ $t('labels.export.non-exported') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  methods: {
    async downloadLeads ({ onlyNotExported = false }) {
      this.$emit('exportLeads', onlyNotExported)
    }
  }
}
</script>
